import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import store from '@/store';
import {authApi} from '@/api';
import {AuthResource} from '@/interfaces';
import {sleep} from '@/utility';
import {ResourceType} from '@/enums';

@Module
class AuthModule extends VuexModule {
  // state
  auth: AuthResource | null = null;
  loading = false;
  googleCredential: string | null = null;

  get isAuthenticated() {
    return this.auth === null ? false : this.auth.attributes.authenticated;
  }

  get isAdmin(): boolean {
    if (this.auth === null) {
      return false;
    }
    if (this.auth.attributes.userGroups.length === 0) {
      return false;
    }

    const adminGroups = this.auth.attributes.userGroups.filter(
      group => group.isAdmin
    );

    return Boolean(adminGroups.length);
  }

  get isAuthenticatedGoogle() {
    return !!this.googleCredential;
  }

  get isLoading() {
    return this.loading;
  }

  @Mutation
  setAuth(auth: AuthResource) {
    this.auth = auth;
  }

  @Mutation
  setGoogleCredential(credential?: string) {
    this.googleCredential = credential ?? null;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @Action
  async signOut() {
    await authApi.delete('1');
    this.setAuth({
      id: '1',
      type: ResourceType.Auth,
      attributes: {authenticated: false, userId: null, userGroups: []},
    });
  }

  @Action
  async checkAuth(googleCredential: string) {
    this.setLoading(true);

    //if (process.env.NODE_ENV === 'development') {
    await sleep(2000);
    //}

    this.setGoogleCredential(googleCredential);
    console.info(
      'User is authenticated with Google:',
      this.isAuthenticatedGoogle
    );

    this.setAuth(await authApi.get('1'));

    // if user is NOT authenticated with google, but we are API authenticated, sign out
    if (!this.isAuthenticatedGoogle && this.isAuthenticated) {
      await authApi.delete('1');
    }

    // get the API auth status
    this.setAuth(await authApi.get('1'));
    console.info('User is authenticated with API:', this.isAuthenticated);

    if (this.isAuthenticatedGoogle && !this.isAuthenticated) {
      this.setGoogleCredential(googleCredential);
    }

    // does user have API auth?
    this.setLoading(false);
  }

  refreshAuth() {
    // check auth every minute
    setInterval(async () => {
      console.log('Periodic auth check');
      await authApi.get('1');
    }, 60000);
  }
}

// register module (could be in any file)

export const authModule = new AuthModule({store, name: 'auth'});
