import {Module} from 'vuex-class-modules';
import store from '@/store';
import {questionApi as api} from '@/api';
import {
  QuestionAttributes as Attributes,
  QuestionCreate as Create,
  QuestionResource as Resource,
} from '@/interfaces';
import {QuestionType, ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Question,
    attributes: {
      id: 0,
      dropdownChainId: '',
      helpText: '',
      items: [],
      label: '',
      name: '',
      order: 0,
      placeholder: '',
      preQuestionLabel: '',
      preQuestionHelpText: '',
      questionCollection: {key: '', label: '', id: 0},
      questionCollectionId: 0,
      readOnly: false,
      repeatable: false,
      surveyQuestion: true,
      type: QuestionType.None,
      validation: {},
      value: '',
      userCreated: {id: 0, firstName: '', lastName: ''},
      userModified: {id: 0, firstName: '', lastName: ''},
      userIdCreated: 0,
      userIdModified: 0,
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class QuestionModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  protected cast(item: Resource): Resource {
    item.attributes.surveyQuestion = Boolean(item.attributes.surveyQuestion);
    item.attributes.repeatable = Boolean(item.attributes.repeatable);
    if (Array.isArray(item.attributes.validation)) {
      item.attributes.validation = {};
    }
    return item;
  }
}

export const questionModule = new QuestionModule(
  {
    store,
    name: ResourceType.Question,
  },
  () => api,
  defaultItem
);
