import {Module} from 'vuex-class-modules';
import store from '@/store';
import {surveyApi as api} from '@/api';
import {
  SurveyAttributes as Attributes,
  SurveyCreate as Create,
  SurveyResource as Resource,
} from '@/interfaces';
import {ResourceType, SurveyType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Survey,
    attributes: {
      id: 0,
      expires: undefined,
      headerImageUrl: '',
      headerText: '',
      logoImageUrl: '',
      name: '',
      publishedDate: undefined,
      published: false,
      message: '',
      notify: '',
      npsData: false,
      sentDate: undefined,
      sentFlag: false,
      subject: '',
      template: false,
      thankYouMessage: '',
      type: SurveyType.IntegratedOpen,
      url: '',
      utm: '',
      userGroupId: 0,
      userGroup: {id: 0, label: '', isAdmin: false},
      userCreated: {id: 0, firstName: '', lastName: ''},
      userModified: {id: 0, firstName: '', lastName: ''},
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SurveyModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  public cast(item: Resource): Resource {
    if (item.attributes.expires) {
      item.attributes.expires = parseISO(`${String(item.attributes.expires)}Z`);
    }
    if (item.attributes.periodEnd) {
      item.attributes.periodEnd = parseISO(
        `${String(item.attributes.periodEnd)}Z`
      );
    }
    if (item.attributes.periodStart) {
      item.attributes.periodStart = parseISO(
        `${String(item.attributes.periodStart)}Z`
      );
    }
    if (item.attributes.sentDate) {
      item.attributes.sentDate = parseISO(
        `${String(item.attributes.sentDate)}Z`
      );
    }
    item.attributes.template = Boolean(item.attributes.template);
    item.attributes.created = parseISO(`${String(item.attributes.created)}`);
    item.attributes.modified = parseISO(`${String(item.attributes.modified)}`);
    return item;
  }
}

export const surveyModule = new SurveyModule(
  {
    store,
    name: ResourceType.Survey,
  },
  () => api,
  defaultItem
);
