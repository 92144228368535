import {Module} from 'vuex-class-modules';
import store from '@/store';
import {surveyFieldApi as api} from '@/api';
import {
  SurveyFieldAttributes as Attributes,
  SurveyFieldCreate as Create,
  SurveyFieldResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.SurveyField,
    attributes: {
      id: 0,
      dataType: '',
      displayControlId: '',
      dropdownChainId: '',
      helpText: '',
      items: [],
      label: '',
      name: '',
      options: {},
      order: 0,
      pageId: 0,
      placeholder: '',
      preQuestionLabel: '',
      preQuestionHelpText: '',
      question: null,
      questionCollectionId: 0,
      questionId: 0,
      readOnly: false,
      repeatable: false,
      storageType: '',
      surveyId: 0,
      type: null,
      validation: {},
      value: '',
      user: {id: 0, firstName: '', lastName: ''},
      userId: 0,
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SurveyFieldModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {
  protected cast(item: Resource): Resource {
    if (!item.attributes.validation) {
      item.attributes.validation = {};
    }
    if (Array.isArray(item.attributes.validation)) {
      item.attributes.validation = {};
    }
    if (item.attributes.validation.required === undefined) {
      item.attributes.validation.required = false;
    }
    item.attributes.displayControlId = String(item.attributes.displayControlId);
    item.attributes.dropdownChainId = String(item.attributes.dropdownChainId);
    return item;
  }
}

export const surveyFieldModule = new SurveyFieldModule(
  {
    store,
    name: ResourceType.SurveyField,
  },
  () => api,
  defaultItem
);
