

















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  mdiAutoFix,
  mdiViewDashboard,
  mdiLogout,
  mdiFormTextbox,
  mdiAccountMultiple,
  mdiAccountGroup,
  mdiTextBoxMultiple,
  mdiEmailSend,
} from '@mdi/js';
import {authModule} from '@/store/modules';
import {Route} from '@/enums';
import {indexApi} from '@/api';

@Component
export default class App extends Vue {
  private apiVersion = '';
  private drawer = false;
  private iconSurveyWizard = mdiAutoFix;
  private iconSurveys = mdiFormTextbox;
  private iconDashboard = mdiViewDashboard;
  private iconSentEmails = mdiEmailSend;
  private iconSignOut = mdiLogout;
  private iconUsers = mdiAccountMultiple;
  private iconUserGroups = mdiAccountGroup;
  private iconQuestionCollections = mdiTextBoxMultiple;
  private route = Route;
  private version = process.env.VERSION;

  get authenticated() {
    return authModule.isAuthenticated;
  }

  get isAuthLoading() {
    return authModule.isLoading;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  async mounted() {
    //await authModule.checkAuth();
    this.apiVersion = await indexApi.getVersion();
    console.log('API version:', this.apiVersion);
    console.log('App version:', this.version);
  }

  async signOut() {
    // sign out of google, manage-api and redirect to auth page
    await authModule.signOut();
    await this.$router.push({name: 'auth'});
  }
}
